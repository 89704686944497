<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab title="Banner" active>
          <b-card-text>
            <Banner />
          </b-card-text>
        </b-tab>
        <b-tab title="Home Page">
          <b-card-text>
            <HomePage />
          </b-card-text>
        </b-tab>
        <b-tab title="Trendy Course">
          <b-card-text>
            <TrendyCourse />
          </b-card-text>
        </b-tab>
        <b-tab title="Category Wise Course">
          <b-card-text>
            <HomeCategoryCourse />
          </b-card-text>
        </b-tab>
        <b-tab title="Category Expand">
          <b-card-text>
            <CategoryExpand />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
import Banner from "./partials/Banner.vue";
import HomePage from "./partials/HomePage.vue";
import TrendyCourse from "./partials/TrendyCourse.vue";
import HomeCategoryCourse from "./partials/HomeCategoryCourse.vue";
import CategoryExpand from "./partials/CategoryExpand.vue";

export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  watch: {},
  components: {
    HomeCategoryCourse,
    TrendyCourse,
    vSelect,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    Banner,
    HomePage,
    CategoryExpand,
  },
};
</script>
